import { Component, OnInit } from "@angular/core";

@Component({ template: '' })
export class BasePage implements OnInit {

    public loading: boolean = false;

    constructor() { }

    ngOnInit(): void { }

    sortArr(arr: any[], key: string, order: boolean, date: boolean = false) {
        if (date) {
            arr.sort((a: any, b: any) => {
                let dateA = Date.parse(a[key]);
                let dateB = Date.parse(b[key]);
                return order ? dateB - dateA : dateA - dateB;
            });
        } else {
            arr.sort((a: any, b: any) => {
                if (isNaN(Number(a[key])) && isNaN(Number(b[key]))) {
                    const aValue = a[key].toLowerCase()
                    const bValue = b[key].toLowerCase();
                    return order ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
                } else {
                    return order ? a[key] - b[key] : b[key] - a[key];
                }
            });
        }
        return arr;
    }
}