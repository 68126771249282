import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Profile } from 'src/app/shared/enums/profile';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private loginEvent: Subject<any> = new Subject<any>();
  private tradeEvent: Subject<any> = new Subject<any>();

  constructor() { }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  getItem(key: string): any {
    return localStorage.getItem(key);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  setLoginEvent(data: any) {
    this.setItem(Profile.VAZEX_BACK_OFFICE_LOGIN_EVENT, JSON.stringify(data));
    this.loginEvent.next(data);
  }

  getLoginEvent(): any {
    let parseResult = null;
    const stringifyResult = this.getItem(Profile.VAZEX_BACK_OFFICE_LOGIN_EVENT);
    if (stringifyResult != null) {
      parseResult = JSON.parse(stringifyResult);
    }
    return parseResult;
  }

  getLoginEventAsObservable() {
    return this.loginEvent.asObservable();
  }

  setTradeEvent(output: boolean) {
    this.tradeEvent.next(output)
  }

  getTradeEventAsObservable() {
    return this.tradeEvent.asObservable();
  }
}
