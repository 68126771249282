import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { delay, of, Subscription } from 'rxjs';
import { RefreshTokenComponent } from 'src/app/pages/auth/refresh-token/refresh-token.component';
import { Profile } from 'src/app/shared/enums/profile';
import { ModalService } from '../modal/modal.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  tokenSubscription = new Subscription();

  constructor(
    private router: Router,
    private storageService: StorageService,
    private modalService: ModalService) { }

  expirationCounter(expiresIn: any) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null).pipe(delay(expiresIn - 60000)).subscribe((expired) => {
      this.modalService.open(RefreshTokenComponent);
    });
  }

  logout() {
    this.tokenSubscription.unsubscribe();
    this.storageService.removeItem(Profile.VAZEX_BACK_OFFICE_LOGIN_EVENT);
    this.router.navigate(['']).then(() => {
    });
  }
}
