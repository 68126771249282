import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  // Name of button
  @Input() name: string = '';
  @Input() disabled: boolean = false;
  @Output() onClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  click(e: any) {
    this.onClicked.emit(e);
  }
}
