<div class="modal-content">
    <div class="modal-header border-0">
        <h5 class="modal-title" id="modalLabel"></h5>
        <button #closeModalButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <h2 style="color: var(--brand-theme-lighter)">BJX<span style="color: var(--brand-theme)">Securities&nbsp;Markets</span></h2>
            <br>
            <h2 class="fw-bold">Session Expire Alert</h2>

            <div>Your session will expire in <span class="fw-bolder">{{ this.counter }} seconds</span>.
                Do you want to extend the session?
            </div>

            <br>
            <div class="row">
                <div class="col-6">
                    <app-button name="Logout" (onClicked)="logout()"></app-button>
                </div>
                <div class="col-6">
                    <app-button name="Extend Session" (onClicked)="extendSession()"></app-button>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>