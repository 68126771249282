import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { ChainEventComponent } from './pages/chain-event/chain-event.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'wallet', component: WalletComponent },
  { path: 'chain-event', component: ChainEventComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
