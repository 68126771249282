import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm!: FormGroup;
  showToast: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private modalService: ModalService) {

  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: new FormControl('', Validators.required),
    });
  }

  closeModal() {
    this.modalService.dismiss();
  }

  forgotPassword() {
    // const payload: any = {
    //   emailAddress: this.forgotPasswordForm.get('email')?.value,
    // }
    // this.apiService.forgotPassword(payload).subscribe({
    //   next: (data: any) => {
    //     if (data.statusCode === 201) {
    //       this.showToast = true;
    //       this.modalService.dismiss();
    //       this.router.navigate(['/home']);
    //     }
    //   },
    //   error: (error: ApiErrorResponse) => {
    //     this.toastrService.error(error.error.message, error.statusText);
    //   }
    // })
  }
}
