import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { StorageService } from './services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vazex-back-office';
  sub!: Subscription;
  firstName: string = '';
  shortFormName: string = '';
  showToast: boolean = false;
  sideMenu: SideMenu[] = [
    {
      icon: 'account_balance_wallet',
      name: 'Wallet',
      link: '/wallet',
      isSelected: true
    },
    {
      icon: 'paid',
      name: 'Transaction',
      link: '/chain-event',
      isSelected: false
    },
  ]

  constructor(
    public router: Router,
    private storageService: StorageService,
    private authService: AuthService) {
    this.initializeApp();
  }

  ngOnInit(): void {
    this.sub = this.storageService.getLoginEventAsObservable().subscribe(data => {
      if (data != null) {
        this.showToast = true;
        this.formatUsername(this.storageService.getLoginEvent().user.fullName);
      }
    })
  }

  initializeApp() {
    if (this.storageService.getLoginEvent() !== null) {
      // Route to default menu
      for (let menu of this.sideMenu) {
        if (menu.isSelected) {
          this.router.navigate([menu.link]);
        }
      }
      this.formatUsername(this.storageService.getLoginEvent().user.fullName);
    }
  }

  formatUsername(firstName: string) {
    this.firstName = firstName;
    this.shortFormName = firstName[0];
  }

  goToMenuModule(selectedMenu: SideMenu) {
    this.sideMenu.filter((menu: SideMenu) => {
      return menu.isSelected = menu.name === selectedMenu.name ? true : false;
    })
    this.router.navigate([selectedMenu.link]);
  }

  logout() {
    this.authService.logout();
  }
}

export interface SideMenu {
  icon: string,
  name: string,
  link: string,
  isSelected: boolean
}