<div class="container-fluid">
    <div class="row align-items-center h-100">

        <div class="col-12 col-lg-3"></div>
        <div class="col-12 col-lg-6">
            <h2 class="fw-bolder text-center">
                <img class="text-center" src="/assets/png/logo.png">Vazex Back Office</h2>
            <div class="row">
                <div class="col"></div>
                <div class="col-8">
                    <form [formGroup]="emailForm" class="mb-3">
                        <div class="col-auto">
                            <div class="input-group mb-2">
                                <div class="input-group-text"><span class="bi bi-person"></span></div>
                                <input formControlName="email" type="text" class="form-control" placeholder="Email Address">
                            </div>
                            <small *ngIf="emailForm.get('email')!.invalid && emailForm.get('email')!.dirty" class="form-text error-message">
                                Email Address is required</small>
                        </div>
                    </form>

                    <form *ngIf="otpRequested" [formGroup]="otpForm" class="mb-3">
                        <p class="muted"><small>Please enter the OTP that we've sent to you via email</small></p>
                        <div class="col-auto">
                            <div class="input-group mb-2">
                                <div class="input-group-text"><span class="bi bi-lock"></span></div>
                                <input formControlName="otp" style="border-right: transparent" type="password" class="form-control" placeholder="OTP" [type]="hide ? 'password' : 'text'">
                                <div class="password-eye" (click)="hide = !hide"><span [ngClass]="{ 'bi bi-eye-slash' : !hide , 'bi bi-eye' : hide }"></span></div>
                            </div>
                            <small *ngIf="otpForm.get('otp')!.invalid && (otpForm.get('otp')!.dirty || otpForm.get('otp')!.touched)" class="form-text error-message">
                                OTP is required</small>
                        </div>
                    </form>

                    <app-button [disabled]="otpRequested ? otpForm.invalid : emailForm.invalid " [name]="otpRequested ? 'Login' : 'Request OTP'" (onClicked)="otpRequested ? verifyOtp() : requestOtp()"></app-button>

                    <!-- <p class="forgot-otp mt-2"><small (click)="navToForgototp()">Forgot otp?</small></p> -->
                </div>
                <div class="col"></div>
            </div>
        </div>
        <div class="col-12 col-lg-3"></div>

    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>