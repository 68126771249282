import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { BasePage } from '../../base.page';

@Component({
  selector: 'app-refresh-token',
  templateUrl: './refresh-token.component.html',
  styleUrls: ['./refresh-token.component.scss']
})
export class RefreshTokenComponent extends BasePage implements OnInit {

  counter: number = 0;
  timer: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private modalService: ModalService,
    private storageService: StorageService) {
      super();
  }

  override ngOnInit() {
    this.counter = 10;
    this.startTimer();
  }

  closeModal() {
    this.modalService.dismiss();
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter === 0) {
        clearInterval(this.timer);
        this.logout();
      }
    }, 1000)
  }

  logout() {
    this.modalService.dismiss();
    this.authService.logout();
  }

  extendSession() {
    this.loading = true;
    // this.apiService.refreshToken().subscribe({
    //   next: (data: any) => {
    //     this.loading = false;
    //     if (data.statusCode === 200) {
    //       this.storageService.setLoginEvent(data.data);
    //       this.authService.expirationCounter(data.data.expiresIn);
    //       this.modalService.dismiss();
    //       clearInterval(this.timer);
    //     }
    //   },
    //   error: (error: ApiErrorResponse) => {
    //     this.loading = false;
    //     this.toastrService.error(error.error.message, error.statusText);
    //   }
    // })
  }
}