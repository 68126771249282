import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }

  private getHeader(contentType: string = 'application/json'): HttpHeaders {
    return contentType == 'multipart/form-data' ?
      new HttpHeaders({
        'Authorization': `Bearer ${this.storageService.getLoginEvent() !== null ? this.storageService.getLoginEvent().token : ''}`,
        // 'x-api-key': `${environment.xApiKey}`
      }) : new HttpHeaders({
        'Content-Type': contentType,
        'Authorization': `Bearer ${this.storageService.getLoginEvent() !== null ? this.storageService.getLoginEvent().token : ''}`,
        // 'x-api-key': `${environment.xApiKey}`
      });
  }

  requestLoginOtp(payload: any) {
    return this.http.post<any>(`${environment.apiUrl}/auth/request-otp`, payload);
  }

  verifyOtp(payload: any) {
    return this.http.post<any>(`${environment.apiUrl}/auth/login-with-otp`, payload);
  }

  getWallets(page: number, itemsPerPage: number, isMerchantWallet: boolean | null, chain: string | null, token: string | null, tokenChain: string | null) {
    let params = new HttpParams();
    params = params.set('page', page);
    params = params.set('perPage', itemsPerPage);
    if (isMerchantWallet != null) {
      params = params.set('isMerchantWallet', isMerchantWallet);
    }
    if (chain != null) {
      params = params.set('chain', chain);
    }
    if (token != null) {
      params = params.set('token', token);
    }
    if (tokenChain != null) {
      params = params.set('tokenChain', tokenChain);
    }
    return this.http.get<any>(`${environment.apiUrl}/wallets`, { headers: this.getHeader(), params: params });
  }

  getChainsEvent(page: number, itemsPerPage: number) {
    let params = new HttpParams();
    params = params.set('page', page);
    params = params.set('perPage', itemsPerPage);
    return this.http.get<any>(`${environment.apiUrl}/chainEvents`, { headers: this.getHeader(), params: params });
  }
}
