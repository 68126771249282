<div class="container-fluid">
    <h1 class="fw-bolder mb-0">Transaction</h1>
    <p class="text-muted">Manage and view transactions from here.</p>

    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="wd-15p border-bottom-0" (click)="sort('token')">Token&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('transactionHash')">Transaction Hash&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('from')">From&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('to')">To&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('readableValue')">Value&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('chain')">Chain&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('createdAt')">Created At&nbsp;<i class="fas fa-sort"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let t of dataTable | paginate: { itemsPerPage, currentPage, totalItems };">
                    <td>{{ t.token }}</td>
                    <td style="cursor: pointer;" matTooltip="{{ t.transactionHash }}">{{ t.formattedTransactionHash }}</td>
                    <td style="cursor: pointer;" matTooltip="{{ t.from }}">{{ t.formattedFrom }}</td>
                    <td style="cursor: pointer;" matTooltip="{{ t.to }}">{{ t.formattedTo }}</td>
                    <td>{{ t.readableValue }}</td>
                    <td>{{ t.chain }}</td>
                    <td>{{ t.createdAt | date:'EEEE, MMMM d, y, h:mm:ss a zzzz' }}</td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="dataTable.length > 0 && !loading" class="d-flex justify-content-center">
            <span class="pt-1" style="font-size: 15px;">Showing {{ (currentPage - 1)*itemsPerPage + 1 }} -
                {{ (currentPage - 1)*itemsPerPage + dataTable.length }} of {{ totalItems }}</span>
            <pagination-controls style="font-size: 15px;" (pageChange)="pageChanged($event)" previousLabel="" nextLabel="" class="my-pagination"></pagination-controls>
        </div>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>