import { Component, Injector, OnInit } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { ApiService } from 'src/app/services/api/api.service';
import { BasePage } from '../base.page';
import { ToastrService } from 'ngx-toastr';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
@Component({
  selector: 'app-chain-event',
  templateUrl: './chain-event.component.html',
  styleUrls: ['./chain-event.component.scss']
})
export class ChainEventComponent extends BasePage implements OnInit {

  // Variables
  itemsPerPage: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;
  reverse: boolean = false;
  config: PaginationInstance = {
    itemsPerPage: this.itemsPerPage,
    currentPage: this.currentPage,
    totalItems: this.totalItems
  };
  dataTable: any[] = [];

  constructor(
    private apiService: ApiService,
    private toastrService: ToastrService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.getChainEvents();
  }

  pageChanged(event: any) {
    this.currentPage = event;
    this.getChainEvents();
  }

  sort(key: string, date: boolean = false) {
    this.reverse = !this.reverse;
    this.dataTable = this.sortArr(this.dataTable, key, this.reverse, date);
  }

  getChainEvents() {
    this.loading = true;
    this.apiService.getChainsEvent(this.currentPage, this.itemsPerPage).subscribe({
      next: (data: any) => {
        this.totalItems = data.metadata.totalCount;
        this.dataTable = data.data;
        this.loading = false;
        for (const data of this.dataTable) {
          data.formattedTransactionHash = this.formatValueIntoShorterRepresentation(data.transactionHash);
          data.formattedFrom = this.formatValueIntoShorterRepresentation(data.from);
          data.formattedTo = this.formatValueIntoShorterRepresentation(data.to);
        }
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.errorMessage, "Error");
      }
    })
  }

  // format string into XXXX....XXXX
  formatValueIntoShorterRepresentation(value: string): string {
    return value !== null ? `${value.substring(0, 4)}....${value.substring(value.length - 4)}` : '-';
  }
}
