<div *ngIf="this.router.url != '/'" class="h-100" style="overflow-y: hidden;">
  <mat-toolbar class="mat-elevation-z8">
    <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
      <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
      <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
    </button>
    <h2 class="mb-0" style="color: var(--brand-theme-lighter)">
      <img class="text-center" src="/assets/png/logo.png">
      <span style="color: var(--brand-theme)">Vazex&nbsp;Back Office</span></h2>
  </mat-toolbar>

  <mat-sidenav-container class="h-100">
    <mat-sidenav #sidenav="matSidenav" opened [disableClose]="true" class="h-100 mat-elevation-z8">

      <div class="row align-items-center p-4">
        <div class="col-auto align-items-center">
          <button class="fab-button">{{ this.shortFormName }}</button>
        </div>
        <div class="col">
          <div class="row fw-bold" style="color: var(--brand-theme)">{{ this.firstName }}</div>
          <div class="row text-dark">Super Admin</div>
        </div>
      </div>

      <button *ngFor="let menu of sideMenu" mat-button class="menu-button p-4" [ngClass]="{ 'selected' : menu.isSelected }" (click)="goToMenuModule(menu)">
        <mat-icon>{{ menu.icon }}</mat-icon>
        <span>{{ menu.name }}</span>
      </button>

      <mat-divider></mat-divider>

      <button mat-button class="menu-button p-4" (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>

    </mat-sidenav>

    <mat-sidenav-content [ngClass]="{ 'side-nav-opened' : sidenav.opened, 'side-nav-closed' : !sidenav.opened }">
      <div class="content mat-elevation-z8">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<router-outlet *ngIf="this.router.url == '/'"></router-outlet>

<app-toast title="All Good!" subTitle="Login successfully" [show]="this.showToast"></app-toast>