import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { BasePage } from '../base.page';
import { ApiService } from 'src/app/services/api/api.service';
import { PaginationInstance } from 'ngx-pagination';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { ToastrService } from 'ngx-toastr';
import { Chain } from 'src/app/shared/enums/chain';
import { Token } from 'src/app/shared/enums/token';
import { TokenChain } from 'src/app/shared/enums/tokenChain';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent extends BasePage implements OnInit {

  // Services
  apiService: ApiService;

  // Variables
  itemsPerPage: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;
  reverse: boolean = false;
  config: PaginationInstance = {
    itemsPerPage: this.itemsPerPage,
    currentPage: this.currentPage,
    totalItems: this.totalItems
  };
  dataTable: any[] = [];
  type: any | null = null;
  typeList: any = [
    {
      name: 'Merchant',
      value: true
    },
    {
      name: 'User',
      value: false
    },
    {
      name: 'All',
      value: false
    }
  ]
  chain: any | null = null;
  chainList: any = Object.keys(Chain).map((key: any) => ({
    name: key,
    value: key
  }));
  token: any | null = null;
  tokenList: any = Object.keys(Token).map((key: any) => ({
    name: key,
    value: key
  }));
  tokenChain: any | null = null;
  tokenChainList: any = [];

  constructor(
    injector: Injector,
    private toastrService: ToastrService
  ) {
    super();
    this.apiService = injector.get(ApiService);
  }

  override ngOnInit(): void {
    this.getWallets();
  }

  pageChanged(event: any) {
    this.currentPage = event;
    this.getWallets();
  }

  sort(key: string, date: boolean = false) {
    this.reverse = !this.reverse;
    this.dataTable = this.sortArr(this.dataTable, key, this.reverse, date);
  }

  getWallets() {
    this.loading = true;
    const isMerchantWallet = this.type != null && this.type != 'All' ? this.typeList.filter((t: any) => { return t.name === this.type })[0].value : null;
    this.apiService.getWallets(this.currentPage, this.itemsPerPage, isMerchantWallet,
      Chain[this.chain as keyof typeof Chain], Token[this.token as keyof typeof Token], TokenChain[this.tokenChain as keyof typeof TokenChain]).subscribe({
        next: (data: any) => {
          this.totalItems = data.metadata.totalCount;
          this.dataTable = data.data;
          for (const data of this.dataTable) {
            const balanceArr: any = [];
            if (!data.isMerchantWallet) {
              const balance = data.balance;
              const merchantBalance = data.merchantBalance;

              for (const key in data.merchantBalance) {
                if (balance[key]) {
                  balance[key] += merchantBalance[key];
                } else {
                  balance[key] = merchantBalance[key];
                }
              }
              data.balance = balance;
            }
            if (data.balance) {
              Object.entries(data.balance).forEach(([key, value]) => {
                balanceArr.push({
                  balance: key + ": " + value
                });
              });
            }
            data.balanceArr = balanceArr;
          }
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.loading = false;
          this.toastrService.error(error.error.errorMessage, "Error");
        }
      })
  }

  typeOnChange(event: any) {
    this.type = event.target.value;
    this.getWallets();
  }

  chainOnChange(event: any) {
    this.chain = event.target.value;
    switch (this.chain) {
      case 'Ethereum':
        this.tokenChainList = Object.keys(TokenChain)
          .filter((key: any) => { return key === 'Ethereum' || key === 'Sepolia' })
          .map((key: any) => ({
            name: key,
            value: key
          }));
        break;
      case 'Tron':
        this.tokenChainList = Object.keys(TokenChain)
          .filter((key: any) => { return key === 'Tron' || key === 'Shasta' })
          .map((key: any) => ({
            name: key,
            value: key
          }));
        break;
      default:
        break;
    }
    this.getWallets();
  }

  tokenOnChange(event: any) {
    this.token = event.target.value;
    this.getWallets();
  }

  tokenChainOnChange(event: any) {
    this.tokenChain = event.target.value;
    this.getWallets();
  }
}