import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiErrorResponse } from 'src/app/models/ApiErrorResponse';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { BasePage } from '../../base.page';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BasePage implements OnInit {

  hide: boolean = true;
  emailForm!: FormGroup;
  otpRequested: boolean = false;
  otpForm!: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private modalService: ModalService,
    private storageService: StorageService,
    private authService: AuthService) {
    super();
  }

  override ngOnInit() {
    this.emailForm = this.formBuilder.group({
      email: new FormControl('', Validators.required)
    });
    this.otpForm = this.formBuilder.group({
      otp: new FormControl('', Validators.required)
    });
  }

  requestOtp() {
    this.loading = true;
    const payload: any = {
      email: this.emailForm.get('email')?.value
    }
    this.apiService.requestLoginOtp(payload).subscribe({
      next: (data: any) => {
        this.otpRequested = true;
        this.emailForm.disable();
        this.loading = false;
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.errorMessage, error.statusText);
      }
    })
  }

  verifyOtp() {
    this.loading = true;
    const payload: any = {
      email: this.emailForm.get('email')?.value,
      otpCode: this.otpForm.get('otp')?.value
    }
    this.apiService.verifyOtp(payload).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.storageService.setLoginEvent(data);
        // this.authService.expirationCounter(data.data.expiresIn);
        this.router.navigate(['/wallet']);
      },
      error: (error: ApiErrorResponse) => {
        this.loading = false;
        this.toastrService.error(error.error.errorMessage, error.statusText);
      }
    })
  }

  navToForgotPassword() {
    this.modalService.open(ForgotPasswordComponent);
  }
}
