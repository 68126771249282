<div class="container-fluid">
    <h1 class="fw-bolder mb-0">Wallet</h1>
    <p class="text-muted">Manage and view wallet details from here.</p>

    <div class="row align-items-center">
        <!-- Type -->
        <!-- <div class="col-12 col-md-2">
            <mat-form-field>
                <mat-label>Cars</mat-label>
                <select matNativeControl required>
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                </select>
            </mat-form-field>
        </div> -->

        <div class="col-12 col-md-2 p-2 form-floating" style="position: relative; min-width: 150px">
            <select class="form-select" id="dropdownMenu" aria-label="Dropdown label" [(ngModel)]="type" (change)="typeOnChange($event)">
                <option [value]="item.name" *ngFor="let item of typeList">
                    {{ item.name }}
                </option>
            </select>
            <span class="label text-muted">Wallet Type</span>
            <span class="label-selected" style="right: 25px;">{{ type }}</span>
        </div>

        <!-- Chain -->
        <div class="col-12 col-md-2 p-2 form-floating" style="position: relative; min-width: 150px">
            <select class="form-select" id="dropdownMenu" aria-label="Dropdown label" [(ngModel)]="chain" (change)="chainOnChange($event)">
                <option [value]="item.name" *ngFor="let item of chainList">
                    {{ item.name }}
                </option>
            </select>
            <span class="label text-muted">Chain</span>
            <span class="label-selected">{{ chain }}</span>
        </div>

        <!-- Token -->
        <div class="col-12 col-md-2 p-2 form-floating" style="position: relative; min-width: 150px">
            <select class="form-select" id="dropdownMenu" aria-label="Dropdown label" [(ngModel)]="token" (change)="tokenOnChange($event)">
                <option [value]="item.name" *ngFor="let item of tokenList">
                    {{ item.name }}
                </option>
            </select>
            <span class="label text-muted">Token</span>
            <span class="label-selected">{{ token }}</span>
        </div>

        <!-- Token Chain -->
        <div *ngIf="tokenChainList.length > 0" class="col-12 col-md-2 p-2 form-floating" style="position: relative; min-width: 150px">
            <select class="form-select" id="dropdownMenu" aria-label="Dropdown label" [(ngModel)]="tokenChain" (change)="tokenChainOnChange($event)">
                <option [value]="item.name" *ngFor="let item of tokenChainList">
                    {{ item.name }}
                </option>
            </select>
            <span class="label text-muted">Token Chain</span>
            <span class="label-selected" style="right: 25px;">{{ tokenChain }}</span>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="wd-15p border-bottom-0" (click)="sort('isMerchantWallet')">Wallet Type&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('address')">Address&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('chain')">Chain&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0" (click)="sort('type')">Type&nbsp;<i class="fas fa-sort"></i></th>
                    <th class="wd-15p border-bottom-0">Balance&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let t of dataTable | paginate: { itemsPerPage, currentPage, totalItems };">
                    <td>{{ t.isMerchantWallet ? 'Merchant' : 'User' }}</td>
                    <td>{{ t.address }}</td>
                    <td>{{ t.chain }}</td>
                    <td>{{ t.type }}</td>
                    <td>
                        <div *ngIf="t.balanceArr.length === 0">-</div>
                        <div *ngIf="t.balanceArr.length > 0">
                            <div *ngFor="let b of t.balanceArr">
                                <span>{{ b.balance }}</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="dataTable.length > 0 && !loading" class="d-flex justify-content-center">
            <span class="pt-1" style="font-size: 15px;">Showing {{ (currentPage - 1)*itemsPerPage + 1 }} -
                {{ (currentPage - 1)*itemsPerPage + dataTable.length }} of {{ totalItems }}</span>
            <pagination-controls style="font-size: 15px;" (pageChange)="pageChanged($event)" previousLabel="" nextLabel="" class="my-pagination"></pagination-controls>
        </div>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>